<template>
  <div class="sidebar">
    <span class="sidebar-heading">Categorieen</span>
    <ul class="sidebar-links">
      <li
        v-for="sidebarLink of sidebarData"
        :key="'sidebarLink' + sidebarLink.id"
      >
        <b-link :href="$link(sidebarLink.linkTo)">{{
          sidebarLink.itemName
        }}</b-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SidebarLinks",
  computed: {
    sidebarData() {
      return this.$store.getters["menu/getMenuItems"];
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  background: #f8f8f8;
  padding: 11px 26px 16px;
}

.sidebar-links {
  list-style: none;
  padding: 3px 0;
  margin: 0;

  li {
    + li {
      margin-top: 6px;
    }
  }

  a {
    position: relative;

    &.active {
      font-weight: 500;
      &:before {
        content: "";
      }
    }

    &:before {
      content: none;
      position: absolute;
      top: -1px;
      left: -24px;
      width: 3px;
      height: 29px;
      background: #ff8000;
    }

    &:hover {
      color: #000 !important;
      font-weight: 500;
      &:before {
        content: "";
      }
    }
  }
}

.sidebar-heading {
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 7px;
}
</style>
