<template>
  <BaseLayout v-slot>
    <section class="static pt-7">
      <b-container>
        <b-row>
          <b-col lg="3" class="d-none d-lg-block">
            <SidebarLinks :sidebarData="sidebarData" />
          </b-col>
          <b-col lg="9">
            <h1>{{ $t("payment_cancel") }}</h1>
            <div v-if="transactionId != null">
              {{ $t("transaction") }}: {{ "#" + transactionId }}
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import SidebarLinks from "@/esf_lommel_dt1_europe/core/components//SidebarLinks";

export default {
  name: "PaymentSuccess",
  components: {
    BaseLayout,
    SidebarLinks,
  },
  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },
  },
  mounted() {
    const bcrumb = { current: "cancel", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  data: () => ({
    sidebarData: {
      heading: "Categorie",
    },
  }),
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 16px;
}

ul {
  li {
    padding-left: 15px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 1px;
      width: 4px;
      height: 4px;
      background: #000;
      border-radius: 100%;
    }
  }
}

.mb-lg-27 {
  margin-bottom: 27px;
}

.font-weight-medium {
  font-weight: 500;
}
</style>
